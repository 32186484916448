import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import InventorySourcesState from '../types/InventorySourcesState'

const getters: GetterTree<InventorySourcesState, RootState> = {
  getInventorySources: (state): object[] => state.items,
  hasItems: (state) => state.items && state.items.length > 0,
  findItems: (state) => ({ key, value }) => state.items.find(p => p[key] === value),
  getCurrent: (state) => state.current,
  getStockItems: (state) => state.stockItems,
  getStockItem: (state) => ({ sku, sourceCode }) => {
    // console.log(sku + '@' + sourceCode)
    return state.stockItems.find(s =>
      (s.sku === sku || !sku) &&
      (s.source_code === sourceCode || !sourceCode))
  },
  getCurrentStockItems: (state, getters) => ({ sku }) => {
    return state.stockItems.filter(s =>
      (s.sku === sku || !sku) &&
      (
        s.source_code === 'online' ||
        (getters.getCurrent && s.source_code === getters.getCurrent.pickup_location_code)
      ))
  },
  getIsPickup: (state) => state.isPickup
}

export default getters
