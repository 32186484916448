import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'
import InventorySourcesState from '../types/InventorySourcesState'

export const inventorySourcesStorageKey = 'inventory_sources'

export const inventorySourcesModule: Module<InventorySourcesState, RootState> = {
  namespaced: true,
  state: {
    items: [],
    current: null,
    loading: false,
    stockItems: [],
    stockLoading: false,
    isPickup: false,
    queue: [],
    queueTimeout: null
  },
  getters,
  actions,
  mutations
}
