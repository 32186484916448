import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers';
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { productThumbnailPath, getThumbnailPath, isServer } from '@vue-storefront/core/helpers'
import { htmlDecode } from '@vue-storefront/core/filters'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import { getProductPrice } from './price'
import { getProductGallery } from '@vue-storefront/core/modules/catalog/helpers'
import { getProductGalleryCard } from './media'
import rootStore from '@vue-storefront/core/store';
import { getBundleBasePriceTotalOld } from 'theme/helpers/bundle';

export * from './price'

export function getPathForStaticPage (path: string) {
  const { storeCode } = currentStoreView()
  const isStoreCodeEquals = storeCode === config.defaultStoreCode

  return isStoreCodeEquals ? `/i${path}` : path
}

export function createSmoothscroll (from = 0, to = 0, speed = 15) {
  let currentDelta = from > to ? from - to : to - from
  let smoothscroll = () => {
    if (Math.abs(currentDelta) < speed) return
    currentDelta = currentDelta - currentDelta / speed
    window.requestAnimationFrame(smoothscroll);
    window.scrollTo(0, from > to ? to + currentDelta : to - currentDelta);
  }
  return smoothscroll()
}

export function checkWebpSupport (bannersToTransform, isWebpSupported) {
  // In SSR it is not easily known whether webp image is supported by web browser or not.
  // Empty string also cannot be used here, because empty string evaluates to url('')
  // and it is resolved as the base URL (the same as our Homepage), so as a consequence
  // Homepage was requested again.
  // To fix that case, dummy empty data URI is provided just to prevent any unnecessary
  // requests.
  // --- see https://github.com/DivanteLtd/vsf-capybara/issues/168
  const theSmallestDummyImage = 'data:,'
  return bannersToTransform.map(banner => Object.assign(
    {},
    banner,
    { image: isServer ? theSmallestDummyImage : isWebpSupported ? banner.image.webp : banner.image.fallback }
  ))
}

export function getTopLevelCategories (categoryList) {
  // Display only the root level (level = 1 => Default Category), categoriesDynamicPrefetchLevel = 2 by default
  const categoryLevel = config.entities.category.categoriesDynamicPrefetchLevel >= 0
    ? config.entities.category.categoriesDynamicPrefetchLevel
    : 2

  return categoryList.filter(
    category => category.level === categoryLevel && category.is_active && category.children_count > 0
  )
}

export function prepareCategoryProduct (product) {
  let price = getProductPrice(product)

  // Overwrite prices with UVP
  if (product.type_id === 'bundle' && product.bundle_options && product.uvp_qm && price.special > 0) {
    const totalBasePrice = product.bundle_options.reduce((sum, option, index) => {
        if (index === 0) {
            // Add the static price instead of the first option's base_price
            return sum + parseFloat(product.uvp_qm);
        } else {
            // For all other options, use the base_price from the first product link
            const firstProductLink = option.product_links[0];
            const basePrice = parseFloat(firstProductLink.base_price);
            return sum + basePrice;
        }
    }, 0);
    price.regular = totalBasePrice * product.baseprice_product_amount
  }

  return {
    id: product.id,
    type: product.type_id,
    sku: product.sku,
    title: htmlDecode(product.name),
    description: product.short_description,
    image: getThumbnailPath(
      productThumbnailPath(product) ?? config.images.productPlaceholder,
      config.products.thumbnails.width,
      config.products.thumbnails.height
    ),
    gallery: getProductGalleryCard(product),
    link: formatProductLink(product, currentStoreView().storeCode),
    price: price,
    base_price: {
      unit: product.baseprice_product_unit,
      factor: product.baseprice_product_amount || 1,
      price: product.baseprice_calculated_price
    },
    selling_unit: product.vk_einheit,
    rating: {
      max: 5,
      score: 5
    },
    badges: prepareBadges(product),
    bundle_options: product.bundle_options,
    stock: {
      sku: (product.type_id == 'bundle') ? product.bundle_options?.find(o => true)?.product_links.find(l => true)?.sku : product.sku,
      qty: product.inventoryStock?.qty,
      qtyPickup: product.inventoryStock?.qtyPickup,
      thresholdQuantity: parseInt(rootStore.getters['storeConfig/getConfigValue']('cataloginventory/options/stock_threshold_qty'))
    }
  }
}

export function prepareBadges (product) {
  let attributes = rootStore.getters['attribute/getAttributeListByCode']

  var badges = [];
  if (product.eigenschaften && attributes.eigenschaften) {
    badges = attributes.eigenschaften.options.filter(o => product.eigenschaften.includes(parseInt(o.value)))
  }

  if (product.news_from_date && product.news_to_date) {
    const now = new Date().getTime()
    if (
      new Date(product.news_from_date).getTime() <= now &&
      new Date(product.news_to_date).getTime() >= now
    ) {
      badges.push({
        label: 'NEU',
        sort_order: 0,
        value: 'new'
      });
    }
  }

  return badges.sort((a,b) => a.sort_order - b.sort_order)
}

export function prepareCategoryMenuItem (category) {
  if (!category) return;

  return {
    id: category.id,
    name: category.name,
    description: category.description,
    link: formatCategoryLink(category),
    count: category.product_count || '',
    position: category.position,
    path: category.path,
    url_key: category.url_key
  };
}
