import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { inventorySourcesModule } from './store'
import routes from './routes'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import { setMagentoPickupLocation } from './helpers/localStorage'

export const InventorySourcesModule: StorefrontModule = async function ({ store, router }) {
  StorageManager.init('inventorySources');
  store.registerModule('inventorySources', inventorySourcesModule)
  // store.dispatch('inventorySources/init');

  var insideSetPickupLocation = false
  store.subscribe(({ type, payload }, state) => {

    if (type === 'checkout/checkout/SET_SHIPPING_METHOD' && !insideSetPickupLocation) {
      insideSetPickupLocation = true

      setMagentoPickupLocation(
        (state.inventorySources.isPickup) ? state.inventorySources.current.pickup_location_code : null
      )
      insideSetPickupLocation = false
    }
  })
  router.addRoutes(routes)
}
