import { optionLabel } from '@vue-storefront/core/modules/catalog/helpers';
import rootStore from '@vue-storefront/core/store'

export function scrollTo (anchor) {
  const element = document.querySelector(anchor)
  const topPos = element.getBoundingClientRect().top + window.pageYOffset

  window.scrollTo({
    top: topPos, // scroll so that the element is at the top of the view
    behavior: 'smooth' // smooth scroll
  })
}

export function basePriceUnit (unit) {
  return optionLabel(
    rootStore.state.attribute,
    { attributeKey: 'baseprice_product_unit', optionId: unit }
  )
}

export function discountPercentage (price, oldPrice) {
  return Math.floor((1 - price / oldPrice) * 100)
}
