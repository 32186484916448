import { mapGetters } from 'vuex'
import { isServer } from '@vue-storefront/core/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  computed: {
    ...mapGetters({
      attributesByCode: 'attribute/getAttributeListByCode',
      getInventorySources: 'inventorySources/getInventorySources',
      selectedStore: 'inventorySources/getCurrent'
    }),
    storeView () {
      return currentStoreView()
    }
  },
  mounted () {
    if (!isServer) {
      this.fetchSources()
    }
  },
  methods: {
    async fetchSources () {
      return this.$store.dispatch('inventorySources/init', {})
    }
  }
}
