import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import InventorySourcesState from '../types/InventorySourcesState'

const mutations: MutationTree<InventorySourcesState> = {
  [types.INVENTORY_SOURCES_UPDATE_SOURCE] (state, sources) {
    state.items = sources || []
  },
  [types.INVENTORY_SOURCES_SET_CURRENT] (state, current) {
    state.current = current
  },
  [types.INVENTORY_SOURCES_LOADING] (state, loading) {
    state.loading = loading || false
  },
  [types.INVENTORY_SOURCES_STOCK_LOADING] (state, loading) {
    state.stockLoading = loading || false
  },
  [types.INVENTORY_SOURCES_UPDATE_STOCK] (state, items) {
    state.stockItems = state.stockItems.concat(items) || []
  },
  [types.INVENTORY_SOURCES_SET_IS_PICKUP] (state, isPickup) {
    state.isPickup = isPickup || false
  },
  [types.INVENTORY_SOURCES_STOCK_QUEUE] (state, isPickup) {
    state.isPickup = isPickup || false
  },
  [types.INVENTORY_SOURCES_STOCK_QUEUE] (state, queue) {
    state.queue = queue
  },
  [types.INVENTORY_SOURCES_STOCK_QUEUE_TIMEOUT] (state, timeout) {
    state.queueTimeout = timeout
  }
}

export default mutations
