import config from 'config'
import {
  hasImage,
  childHasImage
} from '@vue-storefront/core/modules/catalog/helpers'
import { getThumbnailPath } from '@vue-storefront/core/helpers'
import uniqBy from 'lodash-es/uniqBy'
import Product from '@vue-storefront/core/modules/catalog/types/Product';

/**
 * Get media Gallery images from product
 * - for product cards
 */

export function getMediaGalleryCard (product) {
  let mediaGallery = []
  if (product.media_gallery) {
    for (let mediaItem of product.media_gallery) {
      if (mediaItem.image) {
        let video = mediaItem.vid

        if (video && video.video_id) {
          video.id = video.video_id
          delete video.video_id
        }

        mediaGallery.push({
          'src': getThumbnailPath(mediaItem.image, config.products.thumbnails.width, config.products.thumbnails.height),
          'loading': getThumbnailPath(mediaItem.image, config.products.thumbnails.width, config.products.thumbnails.height),
          'error': getThumbnailPath(mediaItem.image, config.products.thumbnails.width, config.products.thumbnails.height),
          'video': video
        })
      }
    }
  }
  return mediaGallery
}

/**
 * Get images from configured attribute images
 * - for product cards
 */
export function attributeImagesCard (product) {
  let attributeImages = []
  if (config.products.gallery.imageAttributes) {
    for (let attribute of config.products.gallery.imageAttributes) {
      if (product[attribute] && product[attribute] !== 'no_selection') {
        attributeImages.push({
          'src': getThumbnailPath(product[attribute], config.products.thumbnails.width, config.products.thumbnails.height),
          'loading': getThumbnailPath(product[attribute], 310, 300),
          'error': getThumbnailPath(product[attribute], 310, 300)
        })
      }
    }
  }
  return attributeImages
}

/**
 * Get configurable_children images from product if any
 * otherwise get attribute images
 * - for product cards
 */

export function configurableChildrenImagesCard (product) {
  let configurableChildrenImages = []
  if (childHasImage(product.configurable_children)) {
    let configurableAttributes = product.configurable_options.map(option => option.attribute_code)
    configurableChildrenImages = product.configurable_children.map(child =>
      ({
        'src': getThumbnailPath((!hasImage(child) ? product.image : child.image), config.products.thumbnails.width, config.products.thumbnails.height),
        'loading': getThumbnailPath((!hasImage(child) ? product.image : child.image), config.products.thumbnails.width, config.products.thumbnails.height),
        'id': configurableAttributes.reduce((result, attribute) => {
          result[attribute] = child[attribute]
          return result
        }, {})
      })
    )
  } else {
    configurableChildrenImages = attributeImagesCard(product)
  }
  return configurableChildrenImages
}

export function getProductGalleryCard (product: Product) {
  if (product.type_id === 'configurable' && product.hasOwnProperty('configurable_children')) {
    if (!config.products.gallery.mergeConfigurableChildren && product.is_configured) {
      return attributeImagesCard(product)
    }
  }

  const productGallery = uniqBy(configurableChildrenImagesCard(product).concat(getMediaGalleryCard(product)), 'src')
    .filter(f => f.src && f.src !== config.images.productPlaceholder)

  return productGallery
}
