<template>
  <div class="store-selector">
    <no-ssr>
      <template v-if="selectedStore">
        <a @click="selectStore">
          Mein Markt
          <span>{{ selectedStore.name }}</span>
        </a>
      </template>
      <template v-else>
        <a @click="selectStore">
          <strong>Click & Collect:</strong>
          <span><span class="desktop-only"> Meinen </span>Markt wählen</span>
        </a>
      </template>
    </no-ssr>
  </div>
</template>

<script>
import InventorySource from '../mixins/InventorySource'
import StoreSelector from '../mixins/StoreSelector'
import { ModalList } from 'theme/store/ui/modals'
import NoSSR from 'vue-no-ssr'

import { SfSelect, SfProductOption } from '@storefront-ui/vue';

export default {
  mixins: [ InventorySource, StoreSelector ],
  components: {
    SfSelect,
    SfProductOption,
    'no-ssr': NoSSR,

  },
  computed: {
    pickupLocation: {
      get () {
        return (this.selectedStore) ? this.selectedStore.pickup_location_code : null
      },
      set (code) {
        this.setCurrentStore(code)
      }
    }
  }
}
</script>
