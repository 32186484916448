import InventorySource from './InventorySource'
import { mapGetters } from 'vuex';

export default {
  mixins: [ InventorySource ],
  props: {
    product: {
      type: Object,
      default: () => {
        return {}
      }
    },
    qty: {
      type: Number,
      default: null
    },
    thresholdQty: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      currentStore: null
    }
  },
  computed: {
    isAvailable () {
      return this.qty && this.qty > 0
    }
  }
}
