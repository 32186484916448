<template>
  <transition name="fade">
    <div v-if="visible" class="sf-mega-menu">
      <SfBar
        :title="title"
        :back="isActive"
        @click:back="change()"
      />
      <div
        class="sf-mega-menu__content"
        :class="{ 'sf-mega-menu__content--is-active': isActive }"
      >
        <div
          class="sf-mega-menu__aside"
          :class="{
            'sf-mega-menu__aside--without-content': !this.$slots.aside,
          }"
        >
          <SfList class="sf-mega-menu__menu-mobile">
            <SfListItem v-for="item in items" :key="item">
              <!-- @slot Custom menu-item markup -->
              <slot name="menu-item" v-bind="{ change, item, active }">
                <div @click="change(item)">
                  <SfMenuItem :label="item" class="sf-mega-menu__menu-item" />
                </div>
              </slot>
            </SfListItem>
            <SfListItem>
              <router-link
                :to="localizedRoute('/posts')"
                @click.native="$store.commit('ui/closeMenu')"
              >
                <SfMenuItem :label="'Ratgeber'" class="sf-mega-menu__menu-item ratgeber" />
              </router-link>
            </SfListItem>
          </SfList>
          <div class="sf-mega-menu__aside-content">
            <slot name="asideTitle" v-bind="{ asideTitle }">
              <h3 v-if="asideTitle" class="sf-mega-menu__aside-title">
                {{ asideTitle }}
              </h3>
            </slot>
            <slot name="aside" />
          </div>
        </div>
        <div class="sf-mega-menu__menu">
          <!-- @slot Slot for menu column -->
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from "vue";
import SfMegaMenuColumn from "@storefront-ui/vue/src/components/organisms/SfMegaMenu/_internal/SfMegaMenuColumn.vue";
import { SfList, SfMenuItem, SfBar } from '@storefront-ui/vue';
Vue.component("SfMegaMenuColumn", SfMegaMenuColumn);

import {
  mapMobileObserver,
  unMapMobileObserver,
} from "@storefront-ui/vue/src/utilities/mobile-observer";

export default {
  name: "SfMegaMenu",
  components: {
    SfList,
    SfMenuItem,
    SfBar,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    asideTitle: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    categoryClicked: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      active: [],
      items: [],
    };
  },
  computed: {
    ...mapMobileObserver(),
    isActive() {
      return this.active.length > 0;
    },
  },
  provide() {
    const megaMenu = {};
    Object.defineProperty(megaMenu, "active", {
      get: () => this.active,
    });
    Object.defineProperty(megaMenu, "updateItems", {
      value: this.updateItems,
    });
    return { megaMenu };
  },
  watch: {
    isMobile: {
      handler(mobile) {
        this.$nextTick(() => {
          // this.active = mobile ? [] : [...this.items];
          this.active = [];
        });
      },
      immediate: true,
    },
    /*visible: {
      handler(visible) {
        if (!visible) return;
        if (this.isMobile) return;
        this.$nextTick(() => {
          this.active = [...this.items];
        });
      },
      immediate: true,
    },*/
    categoryClicked: function(category) {
      this.change(category);
    }
  },
  mounted() {
  },
  beforeDestroy() {
    unMapMobileObserver();
  },
  methods: {
    updateItems(title) {
      if (this.items.includes(title)) return;
      this.items.push(title);
    },
    change(payload) {
      this.active = payload ? [payload] : [];
      this.$emit("change", payload ? payload : "");
      this.$nextTick(() => {
        this.$parent.$forceUpdate();
      });
    },
  },
};
</script>

<style lang="scss">
// @import "~@storefront-ui/shared/styles/components/organisms/SfMegaMenu.scss";
</style>
