<template>
  <span v-if="unit">
    <span class="qty-label">
      {{ unitLabel() }}
    </span>
  </span>
</template>

<script>
import { optionLabel } from '@vue-storefront/core/modules/catalog/helpers';

export default {
  props: {
    product: {
      type: Object,
      required: true,
      default: () => ({})
    },
    unit: {
      type: Number,
      default: null
    },
    qty: {
      type: Number,
      default: 1
    }
  },
  methods: {
    unitLabel () {
      return optionLabel(
        this.$store.state.attribute,
        { attributeKey: 'vk_einheit', optionId: this.unit }
      )
    }
  },
}
</script>
