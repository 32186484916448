import { render, staticRenderFns } from "./o-header-minimal.vue?vue&type=template&id=18390893&scoped=true&"
import script from "./o-header-minimal.vue?vue&type=script&lang=js&"
export * from "./o-header-minimal.vue?vue&type=script&lang=js&"
import style0 from "./o-header-minimal.vue?vue&type=style&index=0&id=18390893&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18390893",
  null
  
)

export default component.exports