import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import BannerState from '../types/BannerState'
import { Logger } from '@vue-storefront/core/lib/logger'

const getters: GetterTree<BannerState, RootState> = {
  getBanners: (state) => state.items,
  hasItems: (state) => state.items && state.items.length > 0,
  getBannersBySlider: (state) => (id) => state.items.filter(p => {
    return parseInt(p.slider_id) === parseInt(id)
  }).sort((a, b) => a.order_banner - b.order_banner)
}

export default getters
