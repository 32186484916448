<template>
  <SfButton
    class="sf-button--pure a-mobile-search-icon"
    @click="goToSearch"
  >
    <img src="/assets/custom/icons/icon-search-dark.svg">
  </SfButton>
</template>

<script>
import { SfIcon, SfButton } from '@storefront-ui/vue';
import { mapState } from 'vuex';

export default {
  components: { SfIcon, SfButton },
  computed: {
    ...mapState({
      isMobileMenu: state => state.ui.isMobileMenu,
      isSearchPanelVisible: state => state.ui.searchpanel
    })
  },
  methods: {
    goToSearch () {
      this.$store.commit('ui/closeMenu')

      this.$store.commit('ui/setSearchpanel', !this.isSearchPanelVisible)
    }
  }
};
</script>
