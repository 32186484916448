import { inventorySourcesStorageKey } from '../store'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import rootStore from '@vue-storefront/core/store'
import { isServer } from '@vue-storefront/core/helpers'

const updateLocalStorage = async (state) => {
  if (isServer) {
    return
  }
  const inventorySourcesStorage = StorageManager.get('inventorySources')
  inventorySourcesStorage.setItem(inventorySourcesStorageKey, state)
}

const fetchLocalStorage = async () => {
  if (isServer) {
    return
  }
  const inventorySourcesStorage = StorageManager.get('inventorySources')
  return inventorySourcesStorage.getItem(inventorySourcesStorageKey) 
}

const setDefaultShippingMethod = (isPickup) => {
  return rootStore.dispatch('checkout/replaceShippingMethods', rootStore.getters['checkout/getShippingMethods'].map((method) => {
    method.default = (
      (isPickup && method.carrier_code === 'instore' && method.method_code === 'pickup') ||
      (!isPickup && method.carrier_code !== 'instore' && method.method_code !== 'pickup')
    ) ? 1 : 0
    return method
  }), { root: true })
}
const setMagentoPickupLocation = async (code) => {
  await setDefaultShippingMethod(code !== null)
  localStorage.setItem('pickup-location-code', code);
}

export {
  updateLocalStorage,
  fetchLocalStorage,
  setMagentoPickupLocation
}
