<template>
  <router-link
    :to="localizedRoute('/')"
    :title="$t('Home Page')"
    class="a-logo"
    @click.native="closeAll"
  >
    <SfImage
      src="/assets/logo.svg"
      :alt="$t(defaultTitle)"
      class="sf-header__logo"
      :lazy="false"
    />
  </router-link>
</template>

<script>
import config from 'config';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { SfImage } from '@storefront-ui/vue';
import get from 'lodash-es/get';

const storeView = currentStoreView();

export default {
  components: { SfImage },
  computed: {
    defaultTitle () {
      return get(storeView, 'seo.defaultTitle', config.seo.defaultTitle);
    }
  },
  methods: {
    closeAll () {
      this.$store.commit('ui/setSidebar', false)
      this.$store.commit('ui/setMicrocart', false)
      this.$store.commit('ui/setSearchpanel', false)
      this.$store.commit('ui/closeMenu')

      return true
    }
  }
};
</script>
<style lang="scss" scoped>
.a-logo {
  display: inline-flex;
}
</style>
