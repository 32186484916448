import config from 'config'
import fetch from 'node-fetch'
import util from 'util'
import rootStore from '@vue-storefront/core/store'

const addProductStockAlert = async (productId) => {
  const url = config.api.url + util.format(
    '/rest/V1/productalertstock/add/%s',
    productId
  )
  const token = rootStore.getters['user/getToken']

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    }
  })
  return response.json()
}

export const ProductAlertsService = {
  addProductStockAlert
}
