<template>
  <SfButton
    class="sf-button--pure a-wishlist-icon"
    @click="toggleWishlistPanel"
  >
    <!--<SfCircleIcon
      icon="heart"
      icon-size="20px"
      class="sf-header__circle-icon"
      role="button"
      aria-label="wishlist"
      @click="toggleWishlistPanel"
    />-->
    <img src="/assets/custom/icons/icon-heart-dark.svg">
    <SfBadge v-show="getWishlistItemsCount" class="badge">
      {{ getWishlistItemsCount }}
    </SfBadge>
  </SfButton>
</template>

<script>
import WishlistIcon from '@vue-storefront/core/compatibility/components/blocks/Header/WishlistIcon';
import { SfCircleIcon, SfBadge, SfButton } from '@storefront-ui/vue';

export default {
  components: { SfCircleIcon, SfBadge, SfButton },
  mixins: [WishlistIcon]
};
</script>

<style lang="scss" scoped>
.a-wishlist-icon {
  position: relative;
}
.badge {
  position: absolute;
  bottom: 2.2em;
  left: 4.2em;
  font-size: 0.6em;
  padding: 0.3em 0;
  border-radius: 100%;
  width: 2.2em;
  min-height: 2.2em;
}
</style>
