import { mapGetters, mapActions } from 'vuex'
import { ModalList } from 'theme/store/ui/modals'

export default {
  data () {
    return {
      selectedPickupLocation: null
    }
  },
  computed: {
    ...mapGetters({
      selectedStore: 'inventorySources/getCurrent'
    })
  },
  watch: {
    selectedPickupLocation (value) {
      if (value) {
        this.setCurrentStore(value)
      }
      this.$store.dispatch(
        'inventorySources/setIsPickup',
        (value)
      )
    }
  },
  methods: {
    setIsPickup (isPickup) {
      this.$store.dispatch(
        'inventorySources/setIsPickup',
        isPickup
      )
    },
    setCurrentStore (code) {
      this.$store.dispatch(
        'inventorySources/setCurrent',
        this.getInventorySources.find((store) => store.pickup_location_code === code)
      )
    },
    ...mapActions('ui', {
      openModal: 'openModal'
    }),
    selectStore () {
      this.openModal({ name: ModalList.StoreSelector, payload: this.product })
    }
  }
}
