export const SN_SOURCE = 'inventorySource'
export const INVENTORY_SOURCES_UPDATE_SOURCE = SN_SOURCE + '/UPDATE_SOURCE'
export const INVENTORY_SOURCES_SET_CURRENT = SN_SOURCE + '/SET_CURRENT'
export const INVENTORY_SOURCES_LOADING = SN_SOURCE + '/LOADING'

export const INVENTORY_SOURCES_STOCK_LOADING = SN_SOURCE + '/STOCK_LOADING'
export const INVENTORY_SOURCES_UPDATE_STOCK = SN_SOURCE + '/UPDATE_STOCK'

export const INVENTORY_SOURCES_SET_IS_PICKUP = SN_SOURCE + '/SET_IS_PICKUP'

export const INVENTORY_SOURCES_STOCK_QUEUE = SN_SOURCE + '/STOCK_QUEUE'
export const INVENTORY_SOURCES_STOCK_QUEUE_TIMEOUT = SN_SOURCE + '/STOCK_QUEUE_TIMEOUT'