<template>
  <footer class="o-footer">
    <div class="c-footer">
      <div class="sf-footer footer-main">
        <div class="sf-footer__container container-xl">
          <div class="sf-footer-column column-contact">
            <button class="sf-footer-column__title">Kontakt & Hilfe</button>

            <div class="sf-footer-column__content">
              <ul class="sf-list">
                <li class="sf-list__item is-mobile">
                  <div class="sf-menu-item sf-menu-item-contact">
                    <a href="tel:0800 782 5982">0800 782 5982</a>
                    <div class="sf-menu-item-contact-open">
                      Mo - Fr von 08:00 - 17:00 Uhr<br>
                      Sa von 10:00 - 15:00 Uhr
                    </div>
                  </div>
                </li>

                <li class="sf-list__item">
                  <div class="sf-menu-item">
                    <router-link :to="localizedRoute('/contacts')" class="sf-menu-item__label">
                      Kontaktformular
                    </router-link>
                  </div>
                </li>

                <li class="sf-list__item">
                  <div class="sf-menu-item">
                    <router-link :to="localizedRoute('/faq')" class="sf-menu-item__label">
                      FAQ (Häufige Fragen)
                    </router-link>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="sf-footer-column column-social">
            <button class="sf-footer-column__title">Social Media</button>

            <div class="sf-footer-column__content">
              <div class="footer-social">
                <a class="social-icon social-youtube" href="https://www.youtube.com/channel/UC4J6N-1AM3HwphYAs4NkuWQ" target="_blank">
                  <SfImage src="/assets/custom/icons/icon-youtube.svg" />
                </a>

                <a class="social-icon social-instagram" href="https://www.instagram.com/ruckzuck.biz/?hl=de" target="_blank">
                  <SfImage src="/assets/custom/icons/icon-instagram.svg" />
                </a>

                <a class="social-icon social-pinterest" href="https://www.pinterest.de/ruckzuckbiz/" target="_blank">
                  <SfImage src="/assets/custom/icons/icon-pinterest.svg" />
                </a>

                <a class="social-icon social-facebook" href="https://www.facebook.com/ruckzuckbodenoutlet/?ref=bookmarks" target="_blank">
                  <SfImage src="/assets/custom/icons/icon-facebook.svg" />
                </a>
              </div>
            </div>
          </div>

          <div class="sf-footer-column">
            <button class="sf-footer-column__title">Kundenservice</button>

            <div class="sf-footer-column__content">
              <ul class="sf-list">
                <li class="sf-list__item">
                  <div class="sf-menu-item">
                    <router-link :to="localizedRoute('/zahlungsarten')" class="sf-menu-item__label">
                      Zahlungsarten
                    </router-link>
                  </div>
                </li>

                <li class="sf-list__item">
                  <div class="sf-menu-item">
                    <router-link :to="localizedRoute('/versandkosten')" class="sf-menu-item__label">
                      Versand & Lieferzeit
                    </router-link>
                  </div>
                </li>

                <li class="sf-list__item">
                  <div class="sf-menu-item">
                    <router-link :to="localizedRoute('/selbstabholung')" class="sf-menu-item__label">
                      Selbstabholung
                    </router-link>
                  </div>
                </li>

                <li class="sf-list__item">
                  <div class="sf-menu-item">
                    <router-link :to="localizedRoute('/muster-anfordern')" class="sf-menu-item__label">
                      Muster anfordern
                    </router-link>
                  </div>
                </li>

                <li class="sf-list__item">
                  <div class="sf-menu-item">
                    <router-link :to="localizedRoute('/garantie')" class="sf-menu-item__label">
                      Garantie
                    </router-link>
                  </div>
                </li>

                <li class="sf-list__item">
                  <div class="sf-menu-item">
                    <router-link :to="localizedRoute('/widerruf')" class="sf-menu-item__label">
                      Widerruf
                    </router-link>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="sf-footer-column">
            <button class="sf-footer-column__title">Über RuckZuck</button>

            <div class="sf-footer-column__content">
              <ul class="sf-list">
                <li class="sf-list__item">
                  <div class="sf-menu-item">
                    <router-link :to="localizedRoute('/about-us')" class="sf-menu-item__label">
                      Über Uns
                    </router-link>
                  </div>
                </li>

                <li class="sf-list__item">
                  <div class="sf-menu-item">
                    <router-link :to="localizedRoute('/store')" class="sf-menu-item__label">
                      Märkte
                    </router-link>
                  </div>
                </li>

                <li class="sf-list__item">
                  <div class="sf-menu-item">
                    <router-link :to="localizedRoute('/ruckzuck-testet.html')" class="sf-menu-item__label">
                      RuckZuck testet
                    </router-link>
                  </div>
                </li>

                <li class="sf-list__item">
                  <div class="sf-menu-item">
                    <router-link :to="localizedRoute('/karriere')" class="sf-menu-item__label">
                      Karriere
                    </router-link>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="sf-footer-column column-usp">
            <button class="sf-footer-column__title">RuckZuck Vorteile</button>

            <div class="sf-footer-column__content footer-usp">
              <ul>
                <li>✓ Fußleisten & Dämmung inklusive</li>
                <li>✓ Lange Garantien auf Bodenbeläge</li>
                <li>✓ Online kaufen und selbst abholen</li>
                <li>✓ Gratis Muster</li>
                <li>✓ Kompetente Beratung</li>
              </ul>

              <div class="footer-trusted-shops">
                <SfImage src="/assets/custom/icons/icon-trusted-shops.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="divider container-xl"></div>

      <div class="footer-bottom container-xl">
        <div class="footer-bottom-payment">
          <SfImage src="/assets/custom/icons/icon-visa.svg" />
          <SfImage src="/assets/custom/icons/icon-mastercard.svg" />
          <SfImage src="/assets/custom/icons/icon-paypal.svg" />
          <SfImage src="/assets/custom/icons/icon-vorkasse.svg" />
        </div>

        <div class="footer-bottom-legal">
          <ul>
            <li>© {{ new Date().getFullYear() }} RuckZuck.biz</li>
            <li><router-link :to="localizedRoute('/agb')">AGB</router-link></li>
            <li><router-link :to="localizedRoute('/datenschutz')">Datenschutz</router-link></li>
            <li><router-link :to="localizedRoute('/datenverarbeitung')">Datenverarbeitung</router-link></li>
            <li><router-link :to="localizedRoute('/impressum')">Impressum</router-link></li>
          </ul>
        </div>
      </div>
    </div>

    <!--
    <SfFooter :column="5" :multiple="true">
      <SfFooterColumn
        v-for="linkGroup in links"
        :key="linkGroup.name"
        :title="$t(linkGroup.name)"
      >
        <SfList>
          <SfListItem v-for="link in linkGroup.children" :key="link.name">
            <router-link v-if="link.link" :to="localizedRoute(link.link)" exact>
              <SfMenuItem class="sf-footer__menu-item" :label="$t(link.name)" />
            </router-link>
            <SfMenuItem
              v-else-if="link.clickHandler"
              class="sf-footer__menu-item"
              :label="$t(link.name)"
              @click="link.clickHandler"
            />
          </SfListItem>
        </SfList>
      </SfFooterColumn>
      <SfFooterColumn :title="$t('Others')">
        <SfList>
          <SfListItem>
            <router-link to="/legal" exact>
              <SfMenuItem
                class="sf-footer__menu-item"
                :label="$t('Legal notice')"
              />
            </router-link>
          </SfListItem>
          <SfListItem>
            <router-link to="/privacy" exact>
              <SfMenuItem
                class="sf-footer__menu-item"
                :label="$t('Privacy policy')"
              />
            </router-link>
          </SfListItem>
          <SfListItem v-if="multistoreEnabled">
            <SfMenuItem
              @click.native="showLanguageSwitcher"
              class="sf-footer__menu-item"
              :label="currentLanguage"
            />
          </SfListItem>
          <SfListItem class="sf-footer__menu-item">
            {{ getVersionInfo }}
          </SfListItem>
        </SfList>
      </SfFooterColumn>
      <SfFooterColumn :title="$t('Social')" class="social-column">
        <div class="social-icon">
          <img
            v-for="item in social"
            :key="item"
            :src="'/assets/icons/' + item + '.svg'"
            class="social-icon__img"
          >
        </div>
      </SfFooterColumn>
    </SfFooter>
    -->

    <ABackToTop bottom="20px" right="20px" visibleoffset="200" class="desktop-only" />
  </footer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ABackToTop from 'theme/components/atoms/a-back-to-top';
import { SfFooter, SfList, SfMenuItem, SfImage } from '@storefront-ui/vue';
import { ModalList } from 'theme/store/ui/modals'
import { getPathForStaticPage } from 'theme/helpers';
import config from 'config';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import get from 'lodash-es/get';

export default {
  name: 'OFooter',
  components: {
    ABackToTop,
    SfFooter,
    SfList,
    SfMenuItem,
    SfImage
  },
  data () {
    return {
      social: ['facebook', 'pinterest', 'twitter', 'youtube']
    };
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
    multistoreEnabled () {
      return get(config, 'storeViews.multistore', false);
    },
    getVersionInfo () {
      return `v${process.env.__APPVERSION__} ${process.env.__BUILDTIME__}`;
    },
    currentLanguage () {
      const { i18n = config.i18n } = currentStoreView();
      return `${i18n.defaultCountry} / ${i18n.defaultLanguage} / ${i18n.currencyCode}`;
    },
    links () {
      return {
        orders: {
          name: 'Orders',
          children: [
            {
              name: 'My account',
              ...this.isLoggedIn
                ? { link: '/my-account' }
                : { clickHandler: () => this.openModal({ name: ModalList.Auth, payload: 'login' }) }
            },
            { name: 'Delivery', link: '/delivery' },
            { name: 'Return policy', link: '/returns' }
          ]
        },
        help: {
          name: 'Help',
          children: [
            { name: 'Customer service', link: '/customer-service' },
            { name: 'Size guide', link: '/size-guide' },
            { name: 'Contact us', link: '/contact' }
          ]
        },
        about: {
          name: 'About us',
          children: [
            {
              name: 'About us',
              link: getPathForStaticPage('/about-us')
            },
            {
              name: 'Customer service',
              link: getPathForStaticPage('/customer-service')
            },
            { name: 'Store locator', link: '/store-locator' }
          ]
        }
      };
    }
  },
  methods: {
    ...mapActions('ui', {
      openModal: 'openModal'
    }),
    showLanguageSwitcher () {
      this.openModal({ name: ModalList.LanguageSwitcher })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-footer {
  @include for-desktop {
    margin: auto;
  }
  .sf-footer {
    --footer-width: auto;
  }
}
</style>
