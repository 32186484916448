import { ActionTree } from 'vuex'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import * as types from './mutation-types'
import RootState from '@vue-storefront/core/types/RootState';
import BannerState from '../types/BannerState'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { bannerStorageKey } from './'
import { createBannerLoadingQuery, createSingleBannerLoadQuery } from '../helpers'

const actions: ActionTree<BannerState, RootState> = {
  async list ({ commit }, { filterValues = null, filterField = 'id', size = 150, start = 0, excludeFields = null, includeFields = null, skipCache = false }) {
    let query = createBannerLoadingQuery({ filterField, filterValues })
    const bannerResponse = await quickSearchByQuery({ query, entityType: 'banner', size: size, excludeFields, includeFields })

    commit(types.BANNERSLIDER_UPDATE_BANNER, bannerResponse.items)
    return bannerResponse.items
  },
  async single ({ getters, commit, dispatch }, { key = 'id', value, excludeFields = null, includeFields = null, skipCache = false, setCurrent = true }) {
    const currentItems = getters.findItems({ key, value })

    if (skipCache || !getters.hasItems || !currentItems) {
      const bannerResponse = await quickSearchByQuery({
        query: createSingleBannerLoadQuery({ key, value }),
        entityType: 'banner',
        excludeFields,
        includeFields
      })

      if (bannerResponse && bannerResponse.items && bannerResponse.items.length > 0) {
        commit(types.BANNERSLIDER_ADD_BANNER, bannerResponse.items[0])
        if (setCurrent) commit(types.BANNERSLIDER_SET_CURRENT, bannerResponse.items[0])
        return bannerResponse.items[0]
      }

      throw new Error('Banner query returned empty result')
    }

    if (currentItems) {
      if (setCurrent) {
        commit(types.BANNERSLIDER_SET_CURRENT, currentItems)
      }
      return currentItems
    }
  },
  async loadFromCache ({ commit }, { key, value, setCurrent }) {
    const bannerStorage = StorageManager.get('banner')
    const storedItems = await bannerStorage.getItem(bannerStorageKey)

    if (storedItems) {
      commit(types.BANNERSLIDER_UPDATE_BANNER, storedItems)
      const resp = storedItems.find(p => p[key] === value)
      if (!resp) {
        throw new Error('Banner query returned empty result')
      }

      if (setCurrent) {
        commit(types.BANNERSLIDER_SET_CURRENT, resp)
      }

      return resp
    }

    throw new Error('Banner query returned empty result')
  },
  addItem ({ commit }, page) {
    commit(types.BANNERSLIDER_ADD_BANNER, page)
  }
}

export default actions
