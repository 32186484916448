const Home = () =>
  import(/* webpackChunkName: "vsf-home" */ 'theme/pages/Home');
const ErrorPage = () =>
  import(/* webpackChunkName: "vsf-error" */ 'theme/pages/Error');
const Product = () =>
  import(/* webpackChunkName: "vsf-product" */ 'theme/pages/Product');
const Category = () =>
  import(/* webpackChunkName: "vsf-category" */ 'theme/pages/Category');
const Checkout = () =>
  import(/* webpackChunkName: "vsf-checkout" */ 'theme/pages/Checkout');
const MyAccount = () =>
  import(/* webpackChunkName: "vsf-my-account" */ 'theme/pages/MyAccount');
const Static = () =>
  import(/* webpackChunkName: "vsf-static" */ 'theme/pages/Static');
const Contacts = () =>
  import(/* webpackChunkName: "vsf-static" */ 'theme/pages/Contacts')
const ResetPassword = () =>
  import(/* webpackChunkName: "vsf-static" */ 'theme/pages/ResetPassword.vue');

const PostsIndex = () => import(/* webpackChunkName: "vsf-wordpress" */ 'src/modules/wordpress/pages/posts/Index')
const PostsShow = () => import(/* webpackChunkName: "vsf-wordpress" */ 'src/modules/wordpress/pages/posts/Show')
const CategoriesIndex = () => import(/* webpackChunkName: "vsf-wordpress" */ 'src/modules/wordpress/pages/categories/Index')
const CategoriesShow = () => import(/* webpackChunkName: "vsf-wordpress" */ 'src/modules/wordpress/pages/categories/Show')

let routes = [
  { name: 'home', path: '/', component: Home, alias: '/pwa.html' },
  { name: 'checkout', path: '/checkout', component: Checkout },
  { name: 'my-account', path: '/my-account', component: MyAccount },
  { name: 'page-not-found', path: '*', component: ErrorPage },
  { name: 'error', path: '/error', component: ErrorPage, meta: { layout: 'minimal' } },
  { name: 'virtual-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'bundle-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'simple-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'downloadable-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'grouped-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'configurable-product', path: '/p/:parentSku/:slug/:childSku', component: Product },
  { name: 'product', path: '/p/:parentSku/:slug/:childSku', component: Product },
  { name: 'category', path: '/c/:slug', component: Category },
  { name: 'cms-page', path: '/i/:slug', component: Static },
  { name: 'posts-index', path: '/posts', component: PostsIndex, props: { page: 'posts', title: 'Posts' } },
  { name: 'posts-show', path: '/posts/:slug', component: PostsShow, props: { page: 'post', title: 'View Post' } },
  { name: 'categories-index', path: '/categories', component: CategoriesIndex, props: { page: 'categories', title: 'View Categories' } },
  { name: 'categories-show', path: '/categories/:slug', component: CategoriesShow, props: { page: 'category', title: 'View Category' } },
  { name: 'contact', path: '/contacts', component: Contacts, props: { page: 'contacts', title: 'Contacts' } },
  { name: 'create-password', path: '/create-password', component: ResetPassword }
];

export default routes;
