<template>
  <div class="o-header">
    <SfOverlay
      class="overlay"
      :visible="isMobileMenu || isSearchPanelVisible"
      @click="$store.commit('ui/closeMenu'); $store.commit('ui/setSearchpanel', false);"
    />

    <div
      class="c-header-wrapper"
      :class="{
        'sf-header--has-mobile-search': isSearchPanelVisible,
        'sf-header--is-sticky': isSearchPanelVisible
      }"
      :style="{'z-index': isMobileMenu || isSearchPanelVisible ? 1 : 0}"
    >
      <div class="c-header-top">
        <div class="c-header-top-inner">
          <ul>
            <li class="header-top-store"><router-link :to="localizedRoute('/store')"><span>Click & Collect in unseren</span><span class="link">{{ (getInventorySources.length > 0) ? getInventorySources.length : 23 }} Märkten</span></router-link></li>
            <li class="header-top-phone"><a href="tel:0800 782 5982"><span>Jetzt beraten lassen:</span> 0800 / 782 5982</a></li>
            <li class="header-top-faq"><router-link :to="localizedRoute('/faq')">FAQ</router-link></li>
            <li class="header-top-contact"><router-link :to="localizedRoute('/contacts')">Kontaktformular</router-link></li>
          </ul>
        </div>
      </div>

      <div class="c-header">
        <div class="c-header-logo">
          <ALogo />
        </div>

        <div class="c-header-content">
          <div class="c-header-content__top">
            <StoreSelector />

            <div class="c-header-search" :class="{'desktop-only': !isSearchPanelVisible}">
              <div class="search-container">
                <OSearch />

                <SfButton
                  v-if="isSearchPanelVisible"
                  class="sf-button--pure form__action-button form__action-button--secondary mobile-only a-cancel-mobile-search-icon"
                  @click="$store.commit('ui/setSearchpanel', false)"
                >
                  {{ $t("Cancel") }}
                </SfButton>
              </div>
            </div>

            <div class="c-header-action">
              <AMobileSearchIcon class="sf-header__action" />

              <AMicrocartIcon class="sf-header__action" />
              <!--<AWishlistIcon class="sf-header__action" />-->
              <AAccountIcon class="sf-header__action" />

              <AMobileMenuIcon class="sf-header__action" />
            </div>
          </div>

          <div class="c-header-navigation">
            <SfHeaderNavigationItem
              v-for="category in categories"
              :key="category.id"
              @mouseover="isHoveredMenu = true"
              @mouseleave="isHoveredMenu = false"
              @click="goToMenu(); categoryClicked = category.name"
              :class="{active: ((categoryClicked === category.name && isMobileMenu) || isCategoryActive(category))}"
            >
              <!--<router-link
                :class="{active: isCategoryActive(category)}"
                :to="categoryLink(category)"
              >-->
              <span>
                {{ category.name }}
              </span>
              <!--</router-link>-->
              <!--<MMenu
                :visible="isHoveredMenu && !isSearchPanelVisible"
                :categories-ids="category.children_data"
                :title="category.name"
                @close="isHoveredMenu = false"
              />-->
            </SfHeaderNavigationItem>

            <SfHeaderNavigationItem
              @mouseover="isHoveredMenu = true"
              @mouseleave="isHoveredMenu = false"
              @click="isHoveredMenu = false"
              class="ratgeber"
            >
              <router-link
                :to="localizedRoute('/posts')"
                @click.native="$store.commit('ui/closeMenu')"
              >
                Ratgeber
              </router-link>
            </SfHeaderNavigationItem>
          </div>
        </div>
      </div>
    </div>

    <!--
    <SfHeader
      :active-icon="activeIcon"
      :class="{
        'sf-header--has-mobile-search': isSearchPanelVisible,
        'sf-header--is-sticky': isSearchPanelVisible
      }"
      :style="{'z-index': isHoveredMenu ? 2 : 1}"
    >
      <template #logo>
        <ALogo />
      </template>

      <template #navigation>
        <SfHeaderNavigationItem
          v-for="category in categories"
          :key="category.id"
          @mouseover="isHoveredMenu = true"
          @mouseleave="isHoveredMenu = false"
          @click="isHoveredMenu = false"
        >
          <router-link
            :class="{active: isCategoryActive(category)}"
            :to="categoryLink(category)"
          >
            {{ category.name }}
          </router-link>
          <MMenu
            :visible="isHoveredMenu && !isSearchPanelVisible"
            :categories-ids="category.children_data"
            :title="category.name"
            @close="isHoveredMenu = false"
          />
        </SfHeaderNavigationItem>
      </template>

      <template #search>
        <div class="search-container">
          <OSearch :class="{'desktop-only': !isSearchPanelVisible}" />
          <SfButton
            v-if="isSearchPanelVisible"
            class="sf-button--text form__action-button form__action-button--secondary mobile-only"
            @click="$store.commit('ui/setSearchpanel', false)"
          >
            {{ $t("Cancel") }}
          </SfButton>
          <br /><StoreSelector />
        </div>
      </template>

      <template #header-icons>
        <div class="sf-header__icons">
          <AAccountIcon class="sf-header__action" />
          <AMicrocartIcon class="sf-header__action" />
        </div>
      </template>
    </SfHeader>
    -->

    <MMenu
      v-show="isMobileMenu"
      class="mobile-menu"
      :categories-ids="categories"
      :category-clicked="categoryClicked"
      @close="$store.commit('ui/closeMenu')"
    />
  </div>
</template>

<script>
import { SfHeader, SfOverlay, SfButton } from '@storefront-ui/vue';
import ALogo from 'theme/components/atoms/a-logo';
// import AAccountIcon from 'theme/components/atoms/a-account-icon';
// import AMicrocartIcon from 'theme/components/atoms/a-microcart-icon';
import AMicrocartIcon from 'theme/components/atoms/a-microcart-icon';
import AWishlistIcon from 'theme/components/atoms/a-wishlist-icon';
import AAccountIcon from 'theme/components/atoms/a-account-icon';
import AMobileSearchIcon from 'theme/components/atoms/a-mobile-search-icon';
import AMobileMenuIcon from 'theme/components/atoms/a-mobile-menu-icon';
import OSearch from 'theme/components/organisms/o-search';
import { mapState, mapGetters } from 'vuex';
import MMenu from 'theme/components/molecules/m-menu';
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers';
import { getTopLevelCategories } from 'theme/helpers';
import StoreSelector from 'src/modules/vsf-inventory-sources/components/StoreSelector';
import SfHeaderNavigationItem from 'theme/components/atoms/a-header-navigation-item';
import InventorySource from 'src/modules/vsf-inventory-sources/mixins/InventorySource'

export default {
  name: 'OHeader',
  mixins: [ InventorySource ],
  components: {
    // SfHeader,
    SfButton,
    ALogo,
    AAccountIcon,
    AWishlistIcon,
    AMicrocartIcon,
    AMobileSearchIcon,
    AMobileMenuIcon,
    OSearch,
    MMenu,
    SfOverlay,
    StoreSelector,
    SfHeaderNavigationItem
  },
  data () {
    return {
      isHoveredMenu: false,
      categoryClicked: null
    }
  },
  computed: {
    ...mapState({
      isSearchPanelVisible: state => state.ui.searchpanel
    }),
    ...mapState('ui', ['isMobileMenu']),
    ...mapGetters('category', ['getCategories', 'getCurrentCategory']),
    ...mapGetters('user', ['isLoggedIn']),
    activeIcon () {
      return this.isLoggedIn ? 'account' : '';
    },
    categories () {
      return getTopLevelCategories(this.getCategories);
    }
  },
  methods: {
    categoryLink (category) {
      return formatCategoryLink(category);
    },
    isCategoryActive (category) {
      return this.getCurrentCategory.path ? this.getCurrentCategory.path.startsWith(category.path) : false;
    },
    goToMenu () {
      this.$store.commit('ui/setSearchpanel', false)

      this.$store.commit('ui/openMenu')
    }
  },
  watch: {
    async isMobileMenu (status) {
      if (this.isMobileMenu) {
        // we can't add this style to body because sfui also add/remove overflow to body and there may be conflict
        document.documentElement.style.overflow = 'hidden'
        document.querySelector('.weglot-container').classList.add('search-visible')
      } else {
        document.documentElement.style.overflow = ''
        if (!this.isSearchPanelVisible) {
          document.querySelector('.weglot-container').classList.remove('search-visible')
        }
      }
    },
    async isSearchPanelVisible (status) {
      if (this.isSearchPanelVisible) {
        document.querySelector('.weglot-container').classList.add('search-visible')
        this.$store.commit('ui/closeMenu')
      } else if (!this.isMobileMenu) {
        document.querySelector('.weglot-container').classList.remove('search-visible')
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.sf-header-navigation-item {
  &::after {
    bottom: 0;
    width: 0;
  }
  &:hover {
    .m-menu {
      opacity: 1;
      visibility: visible;
    }
    &::after {
      width: 100%;
    }
  }
}
.overlay {
  position: absolute;
  z-index: 1;
}
.o-header {
  .mobile-menu {
    position: fixed;
    opacity: 1;
    visibility: visible;
    top: 0;
    z-index: 1;
    --mega-menu-aside-menu-height: calc(100vh - var(--bottom-navigation-height) - var(--bar-height));
  }
}
</style>
