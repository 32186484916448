<template>
  <div class="m-menu sf-mega-menu bg-white">
    <router-link
      :to="localizedRoute('/')"
      :title="$t('Home Page')"
      class="logo"
      @click.native="$emit('close')"
    ></router-link>

    <div class="container-xl container-close">
      <button
        class="btn btn-close"
        @click="$emit('close')"
        title="Menü Schließen"
      ><span>Schließen</span></button>
    </div>

    <SfMegaMenu
      :title="title || currentCategoryTitle"
      :visible="visible"
      :category-clicked="categoryClicked"
    >
      <SfMegaMenuColumn
        v-for="category in categories"
        :key="category.id"
        :title="category.name"
        :class="{ 'has-image': getCategoryImage(category.id) }"
      >
        <div class="sf-mega-menu-column__grid">
          <router-link
            :to="category.link"
            @click.native="$emit('close')"
          >
            <SfImage v-if="getCategoryImage(category.id)" :src="getThumbnailPath( getCategoryImage(category.id) )">
              <div class="category-name">
                {{ category.name }}
              </div>
            </SfImage>
          </router-link>
          <SfList>
            <SfListItem
              v-for="item in category.items"
              :key="item.id"
            >
              <router-link
                :to="item.link"
                @click.native="$emit('close')"
              >
                <SfMenuItem :label="item.name" />
              </router-link>
            </SfListItem>
          </SfList>

          <div
            class="sf-mega-menu-column__box"
            v-if="getBlockContent(category, '1')"
            v-html="getBlockContent(category, '1')"
          />
        </div>

        <div class="sf-mega-menu-column__grid">
          <div
            class="sf-mega-menu-column__box"
            v-if="getBlockContent(category, '2')"
            v-html="getBlockContent(category, '2')"
          />
          <div
            class="sf-mega-menu-column__box"
            v-if="getBlockContent(category, '3')"
            v-html="getBlockContent(category, '3')"
          />
        </div>
        <div class="sf-mega-menu-column__grid">
          <div
            class="sf-mega-menu-column__box"
            v-if="getBlockContent(category, '4')"
            v-html="getBlockContent(category, '4')"
          />
          <div
            class="sf-mega-menu-column__box"
            v-if="getBlockContent(category, '5')"
            v-html="getBlockContent(category, '5')"
          />
        </div>

        <div class="sf-mega-menu-column__grid">
          <div class="sf-mega-menu-column__box sf-mega-menu-column__box-text">
            <div class="sf-mega-menu-column__title">
              Über {{ category.name }}
            </div>
            <div class="sf-mega-menu-column__content">
              <div
                class="category-description"
                v-html="category.description"
              />
              <router-link
                class="link"
                :to="category.link"
                @click.native="$emit('close')"
              >Mehr erfahren</router-link>
              <div
                class="link"
                v-if="getBlockContent(category, 'link')"
                v-html="getBlockContent(category, 'link')"
              />
            </div>
          </div>
        </div>
      </SfMegaMenuColumn>

      <template #aside>
        <div class="aside-menu">
          <SfBanner
            v-for="(banner, i) in banners"
            :key="i"
            :title="banner.title"
            :image="banner.image"
            class="aside-banner"
            :class="`aside-banner--${banner.type}`"
          />
        </div>
      </template>
    </SfMegaMenu>
  </div>
</template>

<script>
import { /*SfMegaMenu, */SfList, SfMenuItem, SfBanner, SfImage } from '@storefront-ui/vue';
import SfMegaMenu from 'theme/components/organisms/o-mega-menu';
import config from 'config'
import get from 'lodash-es/get'
import { prepareCategoryMenuItem } from 'theme/helpers';
import { mapGetters, mapState } from 'vuex';
import { checkWebpSupport } from 'theme/helpers'
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress';
import { getThumbnailPath } from '@vue-storefront/core/helpers'
import SfProductCard from 'theme/components/organisms/o-product-card';

export default {
  components: { SfMegaMenu, SfList, SfMenuItem, SfBanner, SfImage, SfProductCard },
  mixins: [onEscapePress],
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    categoriesIds: {
      type: Array,
      default: () => []
    },
    categoryClicked: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      cmsBlockContent: []
    }
  },
  async created () {
    const categories = this.categories.map(category => 'mega-menu-' + this.getCleanCategoryName(category)); 
    const num = [1,2,3,4,5, 'link'];
    const cartesian = (...a) => a.reduce((a, b) => a.flatMap(d => b.map(e => d + '-' + e)));
    const blockIds = cartesian(categories,num);

    this.$store.dispatch('cmsBlock/list', {
      filterValues: blockIds
    }).then(blocks => {
      this.cmsBlockContent = blocks;
    });
  },
  methods: {
    onEscapePress () {
      this.$emit('close');
    },
    getThumbnailPath (image) {
      return getThumbnailPath(image, 800, 800, 'category');
    },
    getCategoryImage (id) {
      var image = false;
      var category = this.getCategories.find(category => category.id === id);

      if (category) {
        image = category.image;
      }

      return image;
    },
    getCleanCategoryName (category) {
      return category.url_key.replace(/\-\d+?$/,'')
    },
    getBlockContent (category, num) {
      const categoryIdentifier = 'mega-menu-' + category.url_key.replace(/\-\d+?$/,'') + '-' + num
      return this.cmsBlockContent.find(content => categoryIdentifier === content.identifier)?.content
    }
  },
  computed: {
    ...mapState({
      isWebpSupported: state => state.ui.isWebpSupported
    }),
    ...mapGetters({
      getCategories: 'category/getCategories',
      getCurrentCategory: 'category/getCurrentCategory',
      getPromotedOffers: 'promoted/getPromotedOffers'
    }),
    categories () {
      return this.categoriesIds
        .map(({ id, children_data: childrenData = [] }) => {
          const subCategories = childrenData
            .map(subCategory => prepareCategoryMenuItem(
              this.getCategories.find(category => category.id === subCategory.id)
            ))
            .filter(Boolean)
            .sort((a, b) => a.position - b.position)

          const category = this.getCategories.find(category => category.id === id)
          const viewAllMenuItem = prepareCategoryMenuItem({
            ...category,
            name: this.$t('View all'),
            position: 0
          });

          return {
            ...prepareCategoryMenuItem(category),
            items: [viewAllMenuItem, ...subCategories]
          };
        })
        .sort((a, b) => a.position - b.position);
    },
    currentCategoryTitle () {
      return this.getCurrentCategory.name || ''
    },
    banners () {
      return checkWebpSupport(this.getPromotedOffers.menuAsideBanners, this.isWebpSupported)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.m-menu {
  padding: 0;
  position: absolute;
  left: 0;
  width: 100%;
  top: 100%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  .router-link-exact-active {
    --menu-item-font-weight: bold;
  }
}
.aside-menu {
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  @include for-desktop {
     justify-content: space-between;
  }
}
.aside-banner {
  margin-bottom: var(--spacer-sm);
  text-transform: uppercase;
  --banner-height: 300px;
  &--mobile {
    display: none;
    @include for-mobile {
      display: block;
    }
  }
  &--desktop {
    --banner-width: 300px;
    display: none;
    margin: 0 var(--spacer-sm);
    @include for-desktop {
      display: block;
    }
  }
}
</style>
