import config from 'config'
import fetch from 'node-fetch'
import util from 'util'

const getStocksBySkus = async (skus) => {
  const url = config.api.url + util.format(
    '/rest/V1/inventory/source-items?searchCriteria[filter_groups][0][filters][0][field]=sku&searchCriteria[filter_groups][0][filters][0][value]=%s&searchCriteria[filter_groups][0][filters][0][condition_type]=in',
    skus.join(',')
  )
  const response = await fetch(url)
  return response.json()
}

const getInventorySources = async () => {
  const url = config.api.url + util.format(
    '/rest/all/V1/inventory/in-store-pickup/pickup-locations/?searchRequest[scopeType]=%s&searchRequest[scopeCode]=%s',
    'website',
    'base'
  )
  const response = await fetch(url);
  return response.json();
}

const getSalableQuantity = async (sku, stockId) => {
  const url = config.api.url + util.format(
    '/rest/V1/inventory/get-product-salable-quantity/%s/%i',
    sku,
    stockId
  )
  const response = await fetch(url)
  return response.json()
}

const isProductSalable = async (sku, stockId) => {
  const url = config.api.url + util.format(
    '/rest/V1/inventory/is-product-salable/%s/%i',
    sku,
    stockId
  )
  const response = await fetch(url)
  return response.json()
}

export const InventoryService = {
  getStocksBySkus,
  getInventorySources,
  getSalableQuantity,
  isProductSalable
}
