import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import RootState from '@vue-storefront/core/types/RootState';
import InventorySourcesState from '../types/InventorySourcesState'
import { InventoryService } from '../services/Inventory'
import { updateLocalStorage, fetchLocalStorage } from '../helpers/localStorage'
import rootStore from '@vue-storefront/core/store'

const cacheLifetime = 60; // in seconds

var timeout = null;
var queue = [];
var initialized = false;

const actions: ActionTree<InventorySourcesState, RootState> = {
  async loadInventorySources ({ commit, state }) {
    if (state.loading || state.items.length) {
      return state.items
    }

    const result = await InventoryService.getInventorySources()
    commit(types.INVENTORY_SOURCES_UPDATE_SOURCE, result.items)

    await updateLocalStorage(state)

    commit(types.INVENTORY_SOURCES_LOADING, false)

    return result
  },
  async loadStock ({ commit, state }, skus) {
    if (typeof skus === 'string') {
      skus = [skus]
    }

    let nonExistingSkus = skus.filter((s) => !state.stockItems
      .map((s) => s.sku)
      .filter((s) => skus.includes(s)).includes(s)) // filter existing
      .filter((value, index, self) => self.indexOf(value) === index) // unique

    if (nonExistingSkus.length === 0) {
      return state.stockItems
    }

    commit(types.INVENTORY_SOURCES_STOCK_LOADING, true)

    const result = await InventoryService.getStocksBySkus(nonExistingSkus)
    commit(types.INVENTORY_SOURCES_UPDATE_STOCK, result.items.map((s) => {
      s.time = Date.now()
      return s
    }))

    updateLocalStorage(state)

    commit(types.INVENTORY_SOURCES_STOCK_LOADING, false)
  },
  async init ({ commit, dispatch }) {
    if (initialized) {
      return;
    }
    initialized = true

    const storage = await fetchLocalStorage()
    if (storage && storage.items.length > 0) {
      commit(types.INVENTORY_SOURCES_UPDATE_SOURCE, storage.items)
      commit(types.INVENTORY_SOURCES_SET_CURRENT, storage.current)
      commit(types.INVENTORY_SOURCES_SET_IS_PICKUP, storage.isPickup)
      commit(types.INVENTORY_SOURCES_UPDATE_STOCK,
        storage.stockItems.filter((s) => {
          return (Date.now() - (s.time + cacheLifetime * 1000)) < 0
        })
      )
    } else {
      return dispatch('loadInventorySources')
    }
  },
  setCurrent ({ commit, state }, item) {
    commit(types.INVENTORY_SOURCES_SET_CURRENT, item)
    rootStore.dispatch('cart/syncTotals', { forceServerSync: true })
    updateLocalStorage(state)
  },
  setIsPickup ({ commit, state }, isPickup) {
    commit(types.INVENTORY_SOURCES_SET_IS_PICKUP, isPickup)
    updateLocalStorage(state)
  }
}

export default actions
