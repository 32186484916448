import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import BannerState from '../types/BannerState'

const mutations: MutationTree<BannerState> = {
  [types.BANNERSLIDER_UPDATE_BANNER] (state, banners) {
    state.items = banners || []
  },
  [types.BANNERSLIDER_SET_CURRENT] (state, current) {
    state.current = current
  },
  [types.BANNERSLIDER_ADD_BANNER] (state, banner) {
    const record = state.items.find(c => c.id === banner.id)
    if (!record) {
      state.items.push(banner)
    }
  }
}

export default mutations
