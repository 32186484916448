import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import ProductAlertsState from '../types/ProductAlertsState'
import { ProductAlertsService } from '../services/ProductAlerts'

export const productAlertsStore: Module<ProductAlertsState, RootState> = {
  namespaced: true,
  state: {
    product: null
  },
  mutations: {
    setProduct (state, product) {
      state.product = product
    }
  },
  actions: {
    queueStockAlert ({ commit, state }, { product }) {
      if (
	  product.bundle_options && 
	  product.bundle_options.length > 0 &&
          product.bundle_options[0].product_links &&
          product.bundle_options[0].product_links[0].product
      ) {
        product = product.bundle_options[0].product_links[0].product
      }
      commit('setProduct', product)
    },
    sendStockAlert ({ state }) {
      if (state.product) {
        ProductAlertsService.addProductStockAlert(state.product.id)
        state.product = null
      }
    }
  }
}
