import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'
import BannerState from '../types/BannerState'

export const bannerStorageKey = 'banner'

export const bannersliderModule: Module<BannerState, RootState> = {
  namespaced: true,
  state: {
    items: [],
    current: null
  },
  getters,
  actions,
  mutations
}