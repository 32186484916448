import {
  getBundleOptionsValues,
  getSelectedBundleOptions
} from '@vue-storefront/core/modules/catalog/helpers/bundleOptions'
import { getProductPrice } from 'theme/helpers'

export function getSelectedBundleOptionValue (product, option) {
  let selectedOptions = getSelectedBundleOptions(product)
  let optionValues = getBundleOptionsValues(selectedOptions, product.bundle_options)
  const selectedOption = selectedOptions.find(o => option.option_id === o.option_id)
  if (selectedOption) {
    return optionValues.find(val => val.id === selectedOption.option_selections.find(() => true))
  }
  return null
}

export function getBundleOptions(product) {
 return product.bundle_options.filter(option => getSelectedBundleOptionValue(product, option))
}

export function getBundleBasePriceTotal(product) {
  let total = null

  for (const option of getBundleOptions(product)) {
    if (total === null) {
       const price = getProductPrice(product).special || getProductPrice(product).regular
       total = price / product.baseprice_product_amount || 0;
       continue
    }

    const selectedProduct = getSelectedBundleOptionValue(product, option)?.product as any;
    if (!selectedProduct) continue;

    const basePrice = getSelectedBundleOptionValue(product, option).price / selectedProduct.baseprice_product_amount
    if (!isNaN(basePrice)) {
      total += basePrice;
    }
  }

  return total;
}

export function getBundleBasePriceTotalOld(product) {
  let total = null;

  for (const option of getBundleOptions(product)) {
    // Set the initial total using the bundle product price, if available
    if (total === null) {
      total = getProductPrice(product).regular / product.baseprice_product_amount;
      continue;
    }

    const selectedProduct = getSelectedBundleOptionValue(product, option)?.product as any;
    if (!selectedProduct) continue;

    // Calculate and add the base price for the selected product
    const basePrice = selectedProduct.final_price_incl_tax / selectedProduct.baseprice_product_amount;
    if (!isNaN(basePrice)) {
      total += basePrice;
    }
  }

  return total;
}
