<template>
  <SfButton
    class="sf-button--pure a-mobile-menu-icon"
    @click="goToMenu"
  >
    <img src="/assets/custom/icons/icon-menu.svg">
  </SfButton>
</template>

<script>
import { SfIcon, SfButton } from '@storefront-ui/vue';
import { mapState } from 'vuex';

export default {
  components: { SfIcon, SfButton },
  computed: {
    ...mapState({
      isMobileMenu: state => state.ui.isMobileMenu,
      isSearchPanelVisible: state => state.ui.searchpanel
    })
  },
  methods: {
    goToMenu () {
      this.$store.commit('ui/setSearchpanel', false)

      this.isMobileMenu
        ? this.$store.commit('ui/closeMenu')
        : this.$store.commit('ui/openMenu')
    }
  }
};
</script>
